/* Hide the actual radio input */
.custom-radio input[type="radio"] {
    display: none;
}

/* Style the label as a button */
.custom-radio label {
    padding: 10px 20px;
    margin: 5px;
    display: inline;
    /* border: 1px solid black; */
    border-radius: 4px;
    background-color: #ffffff;
    /* color: black; */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}


/* Change background and color when radio is checked */
.custom-radio input[type="radio"]:checked + label {
    background-color: #3fd2f2 !important;
    border: 1px solid #3fd2f2 !important;

    color: #ffffff;
}

/* Hover effect */
.custom-radio label:hover {
    background-color: #3fd2f2 !important;
    border: 1px solid #3fd2f2 !important;

    color: #ffffff;
}
/* css */

.visa-form1 {
    max-width: auto;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  .inside_visa-form {
    max-width: auto;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 0px;
    background-color: #ffffff;
  }
  
  
  button {
    /* width: 100%; */
    padding: 10px;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  /* .select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #e9e9e9 !important;
    
    height: 40px !important;
} */
  
