.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #e9e9e9 !important;
    border-radius: 0px !important;
    height: 45px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 41px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 41px !important;
    position: absolute;
    top: 1px;
    right: 9px !important;
    width: 20px;
}

.select2-container .select2-dropdown {
    position: absolute !important;
    top: 100% !important;
    /* Ensures dropdown opens below the select box */
}

.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 25px !important;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.select2-dropdown {
    border: 1px solid #ccc !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
}




.select2 .select2-container .select2-container--default {
    width: 248%;
}

.styledata {
    width: 58%;
}


@media only screen and (min-width: 768px) and (max-width: 991px) {

    .styledata {
        width: 32%;
    }
}



@media only screen and (min-width: 280px) and (max-width: 345px) {

    .styledata {
        width: 44.5%;
    }
}

@media only screen and (min-width: 346px) and (max-width: 360px) {

    .styledata {
        width: 51%;
    }
}

@media only screen and (min-width: 361px) and (max-width: 376px) {

    .styledata {
        width: 53%;
    }
}

@media only screen and (min-width: 377px) and (max-width: 391px) {

    .styledata {
        width: 55%;
    }
}


@media only screen and (min-width: 392px) and (max-width: 415px) {

    .styledata {
        width: 57%;
    }

    .select2-container--default .select2-selection--single {
        background-color: #fff;
        border: 1px solid #e9e9e9 !important;
        /* border-radius: 30px !important; */
        height: 30px !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: #444;
        line-height: 21px !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        height: 21px !important;
        position: absolute;
        top: 1px;
        right: 9px !important;
        width: 10px;
    }

    .outboundarrow {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid black;
        /* Arrow color */
        margin: auto;
    }

    .select2-container .select2-dropdown {
        position: absolute !important;
        top: 100% !important;
        /* Ensures dropdown opens below the select box */
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
        display: block;
        padding-left: 25px !important;
        padding-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .select2-dropdown {
        border: 1px solid #ccc !important;
        border-top-left-radius: 3px !important;
        border-top-right-radius: 3px !important;
    }

}

/* @media only screen and (min-width: 416px) and (max-width: 500px) {

    .styledata {
        width: 60%;
    }
} */

@media only screen and (min-width: 501px) and (max-width: 549px) {

    .styledata {
        width: 68%;
    }
}

@media only screen and (min-width: 550px) and (max-width: 599px) {

    .styledata {
        width: 53%;
    }
    
}

@media (min-width: 992px) and (max-width: 1199px) {

    .styledata {
        width: 50%;
    }

}